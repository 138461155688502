import React from 'react'

const Background = () => {
  return (
    <svg width="100%" viewBox="0 0 776 1806" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M449.898 639.955C442.368 788.129 477.384 980.374 643.804 1021.79C681.079 1032.42 693.881 1020.27 686.35 982.274C650.581 753.555 819.637 541.933 765.042 309.035C723.249 -70.8965 115.551 -110.409 13.5153 251.665C-126.172 844.739 937.487 1036.6 233.024 1372.46C79.7822 1454.91 0.713685 1491.76 27.8229 1686.29C30.082 1717.82 58.6973 1839.02 99.3611 1795.71C131.365 1763.41 169.77 1741.76 209.68 1721.24C390.032 1634.62 625.731 1517.22 617.448 1283.18C599.375 1100.43 435.59 980.754 355.769 824.982C248.838 620.199 229.636 369.444 306.445 151.363C350.498 35.4843 455.169 8.50918 489.432 147.564C509.011 313.974 436.72 465.947 449.898 639.955Z"
        fill="black"
        fillOpacity="0.15"
      />
      <path
        d="M449.898 639.94C436.72 466.311 509.011 313.959 489.432 147.929C455.546 10.7732 350.874 33.949 306.445 151.728C229.636 369.429 248.838 620.183 355.769 824.966C435.59 980.738 599.375 1100.42 617.448 1283.16C625.355 1517.58 390.032 1634.6 210.433 1721.98C170.523 1742.5 132.118 1764.16 100.114 1796.45C56.8145 1840.52 31.9644 1715.91 28.5758 1687.03C0.713563 1492.89 80.9116 1454.89 233.777 1373.21C937.864 1036.97 -125.043 846.623 14.2682 252.03C115.551 -110.425 723.249 -71.292 765.042 309.019C819.637 541.918 650.581 753.159 686.35 982.258C693.881 1020.25 681.079 1032.41 643.804 1021.77C477.384 979.979 441.991 787.733 449.898 639.94Z"
        fill="black"
        fillOpacity="0.15"
      />
      <path
        d="M1.46633 1146.77C1.46633 1246.44 81.5382 1327.24 180.312 1327.24C279.085 1327.24 359.157 1246.44 359.157 1146.77C359.157 1047.1 279.085 966.303 180.312 966.303C81.5382 966.303 1.46634 1047.1 1.46633 1146.77Z"
        fill="black"
        fillOpacity="0.15"
      />
    </svg>
  )
}

const BackgroundLight = () => {
  return (
    <svg viewBox="0 0 776 872" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M449.198 639.117C436.02 465.488 508.311 313.135 488.733 147.105C454.846 9.95009 350.174 33.1259 305.745 150.905C228.936 368.605 248.138 619.36 355.069 824.143C434.891 979.915 598.675 1099.59 616.748 1282.34C624.655 1516.76 389.332 1633.78 209.734 1721.16C169.823 1741.68 131.418 1763.33 99.4141 1795.63C56.1147 1839.7 31.2646 1715.08 27.876 1686.21C0.0137814 1492.06 80.2118 1454.07 233.078 1372.38C937.164 1036.15 -125.743 845.799 13.5685 251.207C114.852 -111.248 722.549 -72.1151 764.343 308.196C818.938 541.094 649.882 752.336 685.651 981.435C693.181 1019.43 680.379 1031.59 643.104 1020.95C476.684 979.155 441.291 786.91 449.198 639.117Z"
        fill="black"
        fillOpacity="0.05"
      />
    </svg>
  )
}

export { Background, BackgroundLight }
