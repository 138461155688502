import React, { useMemo, createContext, useState, useEffect, useCallback, useReducer } from 'react'
import { getStages, getNights, getEventsForNight, getHappeningsForNight, getMisc } from '../services/Contentful'
import { isWithinInterval, addWeeks, isBefore } from 'date-fns'
import QueryString from 'query-string'

const EventsContext = createContext()

const EventsProvider = ({ children }) => {
  const [nights, setNights] = useState({})
  const [stages, setStages] = useState([])
  const [night, setNight] = useState()
  const [nextNight, setNextNight] = useState()
  const [currentEvents, setCurrentEvents] = useState([])
  const [currentHappenings, setCurrentHappenings] = useState([])
  const [nextHappenings, setNextHappenings] = useState([])
  const [nextEvents, setNextEvents] = useState([])
  const [misc, setMisc] = useState({})

  const archivedNights = useMemo(() => {
    if (nights) {
      return Object.keys(nights)
        .filter((key) => isBefore(new Date(nights[key].end), new Date()))
        .map((key) => nights[key])
        .sort((a, b) => new Date(a.start) - new Date(b.start))
    }
    return []
  }, [nights])

  const nightObj = useMemo(() => {
    if (nights && night && nights[night]) {
      return nights[night]
    }
    return {}
  }, [night, nights])

  const getCurrentNight = useCallback(() => {
    if (nights) {
      const nightArr = Object.keys(nights).map((key) => ({ ...nights[key], id: key }))

      const { volume: volumeParam } = QueryString.parse(window.location.search)

      const _night = nightArr.find(({ start, end, volume }) => {
        if (volumeParam && parseInt(volumeParam) === volume) {
          return true
        }
        return isWithinInterval(new Date(), { start: new Date(start), end: new Date(end) })
      })

      if (_night) {
        setNight(_night.id)

        const _nextNight = nightArr.find(({ start }) =>
          isWithinInterval(new Date(start), { start: new Date(_night.end), end: addWeeks(new Date(_night.end), 1) })
        )

        if (_nextNight) {
          setNextNight(_nextNight.id)
        } else {
          setNextNight(null)
        }
      } else {
        setNight(null)
      }
    }
  }, [nights])

  useEffect(() => {
    getCurrentNight()
  }, [getCurrentNight])

  const getEvents = useCallback(async (nightId) => {
    try {
      const events = await getEventsForNight(nightId)
      return events.sort((a, b) => a.order - b.order)
    } catch (e) {
      console.error(e)
      return e
    }
  }, [])

  const getHappenings = useCallback((nightId) => {
    return getHappeningsForNight(nightId)
  }, [])

  useEffect(() => {
    if (nextNight) {
      getEvents(nextNight).then(setNextEvents)
      getHappenings(nextNight).then(setNextHappenings)
    }
  }, [nextNight, getEvents, getHappenings])

  useEffect(() => {
    if (night) {
      console.log('NIGHT', night)
      getEvents(night).then(setCurrentEvents)
      // getEventsForNight(night).then((data) => setCurrentEvents(data.sort((a, b) => a.order - b.order)))
      getHappeningsForNight(night).then(setCurrentHappenings)
    }
  }, [night, getEvents])

  useEffect(() => {
    getNights().then(setNights)
    getMisc().then(setMisc)
    getStages().then(setStages)
  }, [])

  const state = {
    currentEvents,
    currentHappenings,
    nextEvents,
    nextHappenings,
    stages,
    nights,
    nextNight,
    nightObj,
    night,
    misc,
    getCurrentNight,
    archivedNights,
    getEvents,
    getHappenings
  }

  return <EventsContext.Provider value={state}>{children}</EventsContext.Provider>
}

export { EventsProvider, EventsContext }
