import React from 'react'

const FacebookIcon = () => (
  <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.1841 11.1495H24.1841C21.4227 11.1495 19.1841 13.3881 19.1841 16.1495V19.1495H16.1841V23.1495H19.1841V31.1495H23.1841V23.1495H26.1841L27.1841 19.1495H23.1841V16.1495C23.1841 15.5972 23.6318 15.1495 24.1841 15.1495H27.1841V11.1495Z"
      fill="#ADB7C1"
    />
    <circle cx="21.6838" cy="21.1495" r="20.2297" stroke="#ADB7C1" />
  </svg>
)

export { FacebookIcon }
