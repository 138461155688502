import React, { useContext, useState, useEffect } from 'react'
import { EventsContext } from '../context/EventsContext'
import styled from 'styled-components'
import { Header } from '../components/Header'
import { EventRow } from '../components/EventRow'
import { motion as m } from 'framer-motion'
import { mobile } from '../styles/Media'
import { Background } from '../components/Background'
import { Sublevel } from '../components/Sublevel'
import { UpNext } from '../components/UpNext'
import { colors } from '../services/EventColors'

const MainGallery = ({ isArchive, nightId }) => {
  const [events, setEvents] = useState([])
  const { currentEvents, misc, getEvents } = useContext(EventsContext)

  useEffect(() => {
    if (!isArchive) {
      setEvents(currentEvents)
    } else {
      if (nightId) {
        getEvents(nightId).then(setEvents)
      }
    }
  }, [currentEvents, isArchive, getEvents, nightId])

  return (
    <>
      <Page>
        <Container initial="enter" animate="in">
          <BackgroundContainer>
            <Background />
            <Background />
            <Background />
            <Background />
          </BackgroundContainer>
          <Header />
          {events.map((event) => (
            <EventRow key={event.id} event={event} color={colors[event.color]} />
          ))}
          <EmailLink>
            Want to show your work on thurs.night?{' '}
            <a href="https://linktr.ee/calarts.thurs.night/" target="_blank" rel="noopener noreferrer">
             submit your work here
            </a>
          </EmailLink>
          <Sublevel />
          {!isArchive && <UpNext />}
          {misc && misc.Credits && (
            <Credits>
              <CreditsText>
                Follow us on{' '}
                <a href="https://www.instagram.com/calarts.thurs.night/" target="_blank" rel="noopener noreferrer">
                  Instagram
                </a>{' '}
                and{' '}
                <a href="https://www.facebook.com/calarts.thurs.night/" target="_blank" rel="noopener noreferrer">
                  Facebook
                </a>
                .
              </CreditsText>
              {misc.Credits.content.paragraphs.map((t) => (
                <CreditsText key={t}>{t}</CreditsText>
              ))}
              {misc.Credits.content.creators.map(({ name, role, link }) => (
                <CreditRow key={name}>
                  <CreditName href={link ? link : null} target="_blank" rel="noopener noreferrer">
                    {name}
                  </CreditName>{' '}
                  : <CreditRole>{role}</CreditRole>
                </CreditRow>
              ))}
              <CreditsText>
                thurs.night is grateful for support from the Office of the President, the Office of the Provost, and the
                Office of Marketing & Communications at CalArts.
              </CreditsText>
            </Credits>
          )}
        </Container>
      </Page>
    </>
  )
}

const Credits = styled.div`
  margin-left: 64px;
  color: #fff;
  opacity: 0.9;
  margin-top: 160px;

  @media ${mobile} {
    margin-left: 16px;
    font-size: 12px;
  }
`

const CreditsText = styled.p`
  margin-bottom: 16px;
  max-width: 700px;
  line-height: 150%;
`

const CreditName = styled.a`
  font-family: 'Bw bold';
  color: #fff;
`

const CreditRole = styled.span``

const CreditRow = styled.div`
  margin-bottom: 4px;
`

const EmailLink = styled.p`
  margin-top: 0;
  margin-left: 64px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #fff;
  z-index: 2;
  position: relative;

  a {
    color: white;
  }

  @media ${mobile} {
    font-size: 12px;
    margin-left: 16px;
  }
`

const BackgroundContainer = styled(m.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 30vh;

  svg {
    max-width: 70%;
    height: 100%;
  }
`

const Container = styled(m.div)`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
`

const Page = styled(m.div)`
  background: #2a3139;
  min-height: 100vh;
  padding-bottom: 32px;
  width: 100%;
  top: 0;
  overflow: hidden;
`

export { MainGallery }
