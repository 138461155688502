import React, { createContext, useState, useCallback, useEffect, useRef } from 'react'
import styled from 'styled-components'
import Validator from 'validator'
import { mobile } from '../styles/Media'

const UserContext = createContext()

const UserProvider = ({ children }) => {
  const [username, setUsername] = useState('')
  const [open, setOpen] = useState(false)
  const [error, setError] = useState(false)
  const callback = useRef()

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [open])

  const onUsernameChange = useCallback((evt) => {
    setUsername(evt.target.value)
    setError(false)
  }, [])

  const onUsernameSubmit = useCallback(
    (evt) => {
      evt.preventDefault()
      if (Validator.isAlphanumeric(username)) {
        if (callback.current) {
          callback.current(username)
        }
        setOpen(false)
      } else {
        setUsername('')
        setError(true)
      }
    },
    [username]
  )

  const showUsernameForm = useCallback((cb) => {
    setOpen(true)
    callback.current = cb
  }, [])

  const state = {
    username,
    showUsernameForm
  }

  return (
    <UserContext.Provider value={state}>
      <>
        {children}
        {open && (
          <FormContainer>
            <Backdrop onClick={() => setOpen(false)} />
            <UsernameForm onSubmit={onUsernameSubmit}>
              <Close type="button" onClick={() => setOpen(false)}>
                x
              </Close>
              <label htmlFor="username">Tell us your name </label>
              <input autoFocus id="username" value={username} onChange={onUsernameChange} />
              <button type="submit">Continue</button>
              {error && <small>Usernames must be alphanumeric ( a-Z 0-9 )</small>}
            </UsernameForm>
          </FormContainer>
        )}
      </>
    </UserContext.Provider>
  )
}

const Close = styled.button`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  border: 1px solid #fff;
  margin: 8px;
  font-size: 18px !important;
`

const UsernameForm = styled.form`
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 32px;
  background: #676e74;

  padding: 80px;

  label {
    color: #fff;
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 8px;
  }

  input {
    height: 50px;
    max-width: 400px;
    margin-bottom: 16px;
    color: #676e74;
    font-family: 'Bw bold';
    font-size: 30px;
    line-height: 140%;
    font-weight: 800;
    width: 100%;
  }

  button {
    background: rgba(0, 0, 0, 0);
    border: 1px solid white;
    padding: 8px 20px;
    text-transform: uppercase;
    align-self: start;
    color: #fff;
    font-size: 20px;
    line-height: 140%;
    margin-bottom: 8px;
  }

  small {
    color: #fff;
  }

  @media ${mobile} {
    padding: 16px;
    height: 30vh;
    justify-content: center;
    input {
      width: 100%;
    }
  }
`

const Backdrop = styled.div`
  position: absolute;
  background: #000;
  opacity: 0.8;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const FormContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  pointer-events: all;
`

export { UserProvider, UserContext }
