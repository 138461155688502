import _useMeasure from 'react-use-measure'
import { ResizeObserver } from '@juggle/resize-observer'
import { useState, useRef, useEffect, useCallback, useContext } from 'react'
import { getAsset } from './services/Contentful'
import slugify from 'slugify'
import { EventsContext } from './context/EventsContext'
import { isAfter, isWithinInterval } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { trackPageView } from './services/Analytics'

export const useIsMobile = () => {
  const bound = 767
  const [isMobile, setIsMobile] = useState(window.innerWidth < 767)

  const onResize = useCallback(() => {
    if (window.innerWidth < bound) {
      if (!isMobile) {
        setIsMobile(true)
      }
    } else {
      if (isMobile) {
        setIsMobile(false)
      }
    }
  }, [isMobile])

  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [onResize])

  return isMobile
}

export const useTime = (update) => {
  const [time, setTime] = useState(new Date())
  const timerRef = useRef()
  useEffect(() => {
    timerRef.current = setInterval(() => setTime(new Date()), update || 1000)
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current)
      }
    }
  }, [update])

  return time
}

export const useMeasure = (opts) => {
  return _useMeasure({ scoll: false, polyfill: ResizeObserver, ...opts })
}

export const useContentfulImage = (img) => {
  const [src, setSrc] = useState()
  const [desc, setDesc] = useState('')
  const [title, setTitle] = useState('')
  const [data, setData] = useState({ details: { image: { width: 1, height: 1 } } })
  useEffect(() => {
    if (img) {
      getAsset(img.sys.id).then((resp) => {
        if (resp?.data?.fields?.file?.url) {
          setSrc(resp.data.fields.file.url)
          setDesc(resp.data.fields.description)
          setTitle(resp.data.fields.title)
          setData(resp.data.fields.file)
        }
      })
    } else {
      setSrc('')
      setDesc('')
      setTitle('')
    }
  }, [img])

  return [src, desc, title, data]
}

export const useTitleSize = (title, _sizes) => {
  const sizes = _sizes || [1, 2, 2.3, 3]

  if (!title) {
    return { fontSize: '1em' }
  }
  const titleLength = title.length

  if (titleLength > 60) {
    return { fontSize: `calc(${sizes[0]}vw + 1em)` }
  }

  if (titleLength > 45) {
    return { fontSize: `calc(${sizes[1]}vw + 1em)` }
  }

  if (titleLength > 29) {
    return { fontSize: `calc(${sizes[2]}vw + 1em)` }
  }

  return { fontSize: `calc(${sizes[3]}vw + 1em)` }
}

export const usePermalink = (event) => {
  const { nights, stages } = useContext(EventsContext)
  if (event && event.stage && event.night && nights && nights[event.night]) {
    return `https://thurs.night.calarts.edu/night/${nights[event.night].volume}/${slugify(
      stages[event.stage].toLowerCase()
    )}/`
  }

  return 'https://thurs.night.calarts.edu'
}

export const useEventStatus = (event) => {
  const { start, end, eventStatusBefore, eventStatusActive, eventStatusAfter } = event
  const time = useTime(1000 * 60)
  const [eventStatus, setEventStatus] = useState(eventStatusActive)

  useEffect(() => {
    if (start && end) {
      if (isAfter(time, new Date(start))) {
        if (isWithinInterval(time, { start: new Date(start), end: new Date(end) })) {
          setEventStatus(eventStatusActive)
        } else {
          setEventStatus(eventStatusAfter || eventStatusActive)
        }
      } else {
        setEventStatus(eventStatusBefore || eventStatusActive)
      }
    } else {
      setEventStatus(eventStatusActive)
    }
  }, [time, start, end, eventStatusActive, eventStatusBefore, eventStatusAfter])

  return eventStatus
}

export const useTracking = () => {
  const { listen } = useHistory()
  useEffect(() => {
    const unlisten = listen((location) => {
      trackPageView(location)
    })

    return unlisten
  }, [listen])
}

export const useStage = (stageId) => {
  const { stages } = useContext(EventsContext)
  if (!stages[stageId]) {
    if (stageId === 'sublevel') {
      return 'sub.level'
    }
    return ''
  }
  return stages[stageId]
}
