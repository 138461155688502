import React, { useState, useEffect } from 'react'
import { BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useContentfulImage } from '../hooks'
import styled from 'styled-components'

const Image = (node) => {
  const [src, _, title] = useContentfulImage(node.data.target)

  let width = '50%'

  if (title.includes('[small]')) {
    width = '20%'
  }

  if (title.includes('[medium]')) {
    width = '50%'
  }

  if (title.includes('[large]')) {
    width = '80%'
  }

  if (title.includes('[x-large]')) {
    width = '100%'
  }

  return <img width={width} height="auto" src={src} />
}

let options = {
  renderNode: {
    'embedded-asset-block': Image
  }
}

const ContentfulText = ({ text }) => {
  return <TextContainer>{documentToReactComponents(text, options)}</TextContainer>
}

const TextContainer = styled.div`
  white-space: pre-line;
  b,
  strong {
    font-family: 'Bw bold';
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 120%;
    font-family: 'Bw bold';
  }
`

export { ContentfulText }
