import React, { useContext, useEffect } from 'react'
import { EventsContext } from '../context/EventsContext'
import { MainGallery } from './MainGallery'
import { InactiveLanding } from './InactiveLanding'

const Landing = () => {
  const { night, getCurrentNight } = useContext(EventsContext)

  useEffect(() => {
    const interval = setInterval(() => {
      getCurrentNight()
    }, 1000 * 60 * 5)
    return () => clearInterval(interval)
  }, [getCurrentNight])

  if (night) {
    return <MainGallery />
  }

  return <InactiveLanding />
}

export { Landing }
