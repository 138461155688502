import React, { useContext } from 'react'
import { EventsContext } from './context/EventsContext'
import slugify from 'slugify'
import { Switch, Route } from 'react-router-dom'
import { Stage } from './pages/Stage'

const RoomRouter = () => {
  const { stages } = useContext(EventsContext)

  if (!stages) {
    return null
  }

  return (
    <Switch>
      {Object.keys(stages).map((key) => (
        <Route
          key={key}
          path={`/${slugify(stages[key].toLowerCase())}/:volumeNumber?`}
          render={(routerProps) => <Stage stageId={key} stageName={stages[key]} {...routerProps} />}
        />
      ))}
    </Switch>
  )
}

export { RoomRouter }
