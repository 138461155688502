import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { EventsContext } from '../context/EventsContext'
import { useContentfulImage } from '../hooks'
import { mobile } from '../styles/Media'
import { useLocation, useHistory, useParams } from 'react-router-dom'

const Item = ({ thumbnail, title, subtitle, link }) => {
  const [src] = useContentfulImage(thumbnail)

  return (
    <ListItem href={link} target="_blank" rel="noopener noreferrer">
      <ListImage src={src} />
      <ListText>
        <ListTitle>{title}</ListTitle>
        <ListSubtitle>{subtitle}</ListSubtitle>
      </ListText>
    </ListItem>
  )
}

const Sublevel = () => {
  const [happenings, setHappenings] = useState([])
  const { currentHappenings, currentEvents, nights, getHappenings } = useContext(EventsContext)
  const { nightId } = useParams()
  const location = useLocation()
  const history = useHistory()
  const ref = useRef()

  useEffect(() => {
    if (nightId) {
      const night = Object.keys(nights).find((key) => nights[key].volume === parseInt(nightId))
      getHappenings(night).then(setHappenings)
    } else {
      setHappenings(currentHappenings)
    }
  }, [nightId, nights, currentHappenings, getHappenings])

  useEffect(() => {
    if (location.hash && location.hash === '#sublevel' && currentEvents.length) {
      if (ref.current) {
        const bounds = ref.current.getBoundingClientRect()
        window.scrollTo({ left: 0, top: bounds.top, behavior: 'smooth' })
        history.replace('/')
      }
    }
  }, [location, currentEvents, history])

  return (
    <Container ref={ref} id="sublevel">
      <Header>sub.level</Header>
      <List>
        {happenings.map((happening) => (
          <Item key={happening.id} {...happening} />
        ))}
      </List>
    </Container>
  )
}

const ListTitle = styled.h3`
  margin: 0;
  margin-bottom: 8px;
  font-size: 36px;
  font-weight: 300;
  font-family: 'Bw bold';

  @media ${mobile} {
    font-size: 20px;
  }
`
const ListSubtitle = styled.p`
  margin: 0;
  font-size: 24px;

  @media ${mobile} {
    font-size: 20px;
  }
`

const ListText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;

  @media ${mobile} {
    max-width: calc(100% - 80px);
  }
`
const ListImage = styled.div`
  width: 122px;
  height: 122px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  margin-right: 32px;

  @media ${mobile} {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    margin: 0;
  }
`

const List = styled.div`
  color: #fff;
`

const ListItem = styled.a`
  text-decoration: none;
  position: relative;
  display: flex;
  margin-bottom: 16px;
  color: #fff;

  @media ${mobile} {
    border-top: 1px solid white;
    margin: 0 -16px;
    padding: 16px;
    min-height: 100px;

    &:last-of-type {
      border-bottom: 1px solid white;
    }
  }

  transition: all 0.3s ease-out;

  &:hover {
    color: #fade01;
  }
`
const Header = styled.h1`
  text-align: center;
  font-size: 160px;
  font-family: 'Bw bold';
  color: #676e74;
  margin: 32px 0;

  @media ${mobile} {
    font-size: 64px;
  }
`

const Container = styled.div`
  position: relative;
  margin-top: 0;
  margin: 64px;
  margin-left: 76px;

  @media ${mobile} {
    margin: 16px;
    margin-bottom: 64px;
  }
`

export { Sublevel }
