import React, { useContext, useState, useEffect } from 'react'
import { EventsContext } from '../context/EventsContext'
import styled, { keyframes } from 'styled-components'
import { useTitleSize, useTime, useIsMobile, useStage } from '../hooks'
import { colors } from '../services/EventColors'
import { motion as m, AnimatePresence } from 'framer-motion'
import { Logo } from '../components/Logo'
import { format } from 'date-fns'
import CalartsLogo from '../assets/calarts-logo.png'
import { Claps, Social, Comments } from '../components/Comments'
import { Chevron } from '../components/Chevron'
import { RoomNavigation } from '../components/RoomNavigation'
import { Block } from '../components/Block'
import { useHistory, useParams } from 'react-router-dom'

import {
  StageContainer,
  TopNav,
  Calarts,
  LogoContainer,
  DateTime,
  Date as DateEle,
  Clock,
  CommentsContainer,
  CommentSticky,
  BrowserView,
  NavButtonContainer,
  NavButton,
  NavText,
  NavigationContainer,
  TitleContainer,
  Title,
  EventContent,
  MobileUi,
  MobileView,
  UiButton,
  MobileUiContainer,
  CloseMobileUI,
  MobileClaps
} from './Stage.style'

const StageLoading = () => {
  const history = useHistory()
  useEffect(() => {
    const timeout = setTimeout(() => {
      history.push('/')
    }, 10 * 1000)

    return () => clearTimeout(timeout)
  }, [])

  return (
    <LoadingContainer>
      <LogoContainer>
        <Logo />
      </LogoContainer>
    </LoadingContainer>
  )
}

const dash = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  opacity: 0.5;

  ${LogoContainer} {
    position: relative;
    transform: none;
    left: unset;
    top: unset;
    height: auto;
  }

  svg path {
    fill: none;
    stroke: #f1f1f1;
    stroke-width: 4px;
    stroke-dasharray: 1000;

    stroke-dashoffset: 1000;
    animation: ${dash} 5s ease-in-out alternate infinite;
  }
`

const Stage = ({ stageId, match }) => {
  const { volumeNumber } = match.params
  const { events, currentEvents, nights, getEvents } = useContext(EventsContext)
  const [event, setEvent] = useState({})
  const { nightId } = useParams()

  useEffect(() => {
    if (nightId) {
      const night = Object.keys(nights).find((key) => nights[key].volume === parseInt(nightId))
      getEvents(night).then((events) => {
        setEvent(events.find((event) => event.stage === stageId))
      })
    } else if (volumeNumber) {
      setEvent(
        events.find((event) => {
          return nights[event.night]?.volume === parseInt(volumeNumber) && event.stage === stageId
        })
      )
    } else {
      setEvent(currentEvents.find((event) => event.stage === stageId))
    }
  }, [currentEvents, stageId, events, volumeNumber, nights, nightId, getEvents])

  if (!event) {
    return <StageLoading />
  }

  return <StageContent event={event} />
}

const StageContent = ({ event }) => {
  const { nights } = useContext(EventsContext)
  const { nightId } = useParams()
  const color = colors[event.color]
  const date = useTime(1000 * 30)
  const time = format(date, 'h:mm a')
  const day = format(date, 'MMM d, yyyy')
  const [navOpen, setNavOpen] = useState(false)
  const [page, setPage] = useState('comments')
  const [mobileUiVisible, setMobileUiVisible] = useState(false)
  const isMobile = useIsMobile()

  const { fontSize } = useTitleSize(event.title, [1, 1.5, 2.2, 2.5])

  const stageName = useStage(event.stage)

  const archiveDate =
    nightId && event?.night && nights[event.night] ? format(new Date(nights[event.night].start), 'MMM d, yyy') : null

  return (
    <>
      <StageContainer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: 'spring', mass: 2, damping: 100, delay: 0.3 }}
        color={color || '#808080'}
      >
        <TopNav to={nightId ? `/night/${nightId}` : '/'}>
          <Calarts src={CalartsLogo} />
          <LogoContainer>
            <Logo />
          </LogoContainer>
          <DateTime>
            <DateEle>{archiveDate || day}</DateEle>
            <Clock style={{ textAlign: nightId ? 'right' : 'left' }}>{nightId ? `Vol. ${nightId}` : time}</Clock>
          </DateTime>
        </TopNav>
        <CommentsContainer>
          <CommentSticky>
            {!isMobile && (
              <BrowserView>
                <Claps event={event} />
                <Social event={event} />
                <Comments event={event} />
              </BrowserView>
            )}
          </CommentSticky>
        </CommentsContainer>
        <NavButtonContainer>
          <NavButton onClick={() => setNavOpen(!navOpen)}>
            <m.div
              style={{ transformOrigin: 'center' }}
              animate={{ rotateX: navOpen ? 0 : 180 }}
              transition={{ type: 'spring', mass: 2, damping: 100 }}
            >
              <Chevron />
            </m.div>
            <NavText>{stageName}</NavText>
          </NavButton>
          <AnimatePresence>
            {navOpen && (
              <NavigationContainer>
                <RoomNavigation event={event} setNavOpen={setNavOpen} />
              </NavigationContainer>
            )}
          </AnimatePresence>
        </NavButtonContainer>
        <TitleContainer>
          <Title style={{ fontSize }}>{event.title}</Title>
        </TitleContainer>
        <MobileView>
          <MobileClaps>
            <Claps event={event} />
          </MobileClaps>
        </MobileView>
        <EventContent>
          {event.blocks && event.blocks.map((block) => <Block key={block.sys.id} blockData={block} />)}
        </EventContent>
        <MobileView>
          <MobileUi>
            <UiButton
              onClick={() => {
                setMobileUiVisible(true)
                setPage('comments')
              }}
            >
              Comments
            </UiButton>
            <UiButton
              onClick={() => {
                setMobileUiVisible(true)
                setPage('social')
              }}
            >
              Share
            </UiButton>
            {isMobile && mobileUiVisible && (
              <MobileUiContainer>
                <CloseMobileUI onClick={() => setMobileUiVisible(false)}>Close</CloseMobileUI>
                {page === 'comments' && <Comments event={event} />}
                {page === 'social' && <Social event={event} />}
              </MobileUiContainer>
            )}
          </MobileUi>
        </MobileView>
      </StageContainer>
    </>
  )
}

export { Stage }
