import React from 'react'
import { useContentfulImage } from '../hooks'
import styled from 'styled-components'
import { mobile } from '../styles/Media'
import { BlockContainer } from './Block.style'

const ExternalLinkBlock = ({ data }) => {
  const [src] = useContentfulImage(data.posterImage)

  if (src) {
    return (
      <Container>
        <a href={data.linkUrl} target="_blank" rel="noopener noreferrer">
          <HeroImage src={src} />
        </a>
        <FixedExternalLink href={data.linkUrl} target="_blank" rel="noopener noreferrer">
          {data.linkText}
        </FixedExternalLink>
      </Container>
    )
  }

  return (
    <Container>
      <ExternalLink href={data.linkUrl} target="_blank" rel="noopener noreferrer">
        {data.linkText}
      </ExternalLink>
    </Container>
  )
}

const Container = styled(BlockContainer)`
  display: flex;
  align-items: center;
  justify-content: space-around;
`

export const HeroImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 800px;
`

export const ExternalLink = styled.a`
  font-family: 'Bw bold';
  display: block;
  background: var(--bg);
  font-size: 30px;
  padding: 12px 48px;
  text-align: center;
  text-decoration: none;
  color: #000;

  @media ${mobile} {
    font-size: 16px;
  }
`

export const FixedExternalLink = styled(ExternalLink)`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
`

export { ExternalLinkBlock }
