import React from 'react'

const TwitterIcon = () => (
  <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.6641 12.1878C31.7064 12.8633 30.6462 13.38 29.5241 13.7178C28.2904 12.2994 26.3024 11.802 24.5461 12.4723C22.7899 13.1426 21.6389 14.8381 21.6641 16.7178V17.7178C18.0947 17.8104 14.7161 16.1098 12.6641 13.1878C12.6641 13.1878 8.66406 22.1878 17.6641 26.1878C15.6046 27.5858 13.1512 28.2868 10.6641 28.1878C19.6641 33.1878 30.6641 28.1878 30.6641 16.6878C30.6631 16.4093 30.6364 16.1314 30.5841 15.8578C31.6047 14.8513 32.3249 13.5806 32.6641 12.1878Z"
      fill="#ADB7C1"
    />
    <circle cx="21.6642" cy="21.1495" r="20.2297" stroke="#ADB7C1" />
  </svg>
)

export { TwitterIcon }
