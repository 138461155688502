import React, { useState, useCallback, useEffect, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { EventsContext } from '../context/EventsContext'
import { Logo } from '../components/Logo'
import { motion as m } from 'framer-motion'
import { format, isAfter } from 'date-fns'
import { mobile, se } from '../styles/Media'
import { Background } from '../components/Background'
import { ImagesBlock } from '../components/ImagesBlock'
import { getLandingPageUpcoming, getLandingPagePrevious } from '../services/Contentful'
import { Link } from 'react-router-dom'
const InactiveLanding = () => {
  const [dist, setDist] = useState()
  const [upcoming, setUpcoming] = useState()
  const [previous, setPrevious] = useState()
  const { nights, getCurrentNight, archivedNights } = useContext(EventsContext)
  const [nextNight, setNextNight] = useState(null)
  const [nextNightEnd, setNextNightEnd] = useState(null)
  useEffect(() => {
    getLandingPagePrevious().then((res) => setPrevious(res.fields))
    getLandingPageUpcoming().then((res) => setUpcoming(res.fields))
  }, [])
  useEffect(() => {
    const today = new Date()
    const nightsArr = Object.keys(nights).map((key) => ({ ...nights[key], id: key }))
    const upcomingNights = nightsArr
      .filter(({ start }) => isAfter(new Date(start), today))
      .sort((a, b) => new Date(a.start) - new Date(b.start))
    if (upcomingNights[0]) {
      setNextNight(new Date(upcomingNights[0].start))
      setNextNightEnd(new Date(upcomingNights[0].end))
    }
  }, [nights])
  const updateDist = useCallback(() => {
    const today = new Date()
    if (nextNight) {
      const difference = nextNight.getTime() - today.getTime()
      getCurrentNight()
      const days = Math.floor(difference / (1000 * 60 * 60 * 24))
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((difference % (1000 * 60)) / 1000)
      setDist({
        days,
        hours,
        minutes,
        seconds
      })
    }
  }, [nextNight, getCurrentNight])
  useEffect(() => {
    const interval = setInterval(updateDist, 1000)
    return () => clearInterval(interval)
  }, [updateDist])
  return (
    <Page>
      <BackgroundContainer>
        <Background />
      </BackgroundContainer>
      <Content>
        <Header>
          <Text style={{ y: '2vw' }}>it's not</Text>
          <LogoContainer>
            <Logo />
          </LogoContainer>
          {nextNight && <Text style={{ y: '-2vw' }}>yet</Text>}
        </Header>
        {nextNight && (
          <Wrapper>
            {dist && (
              <CountDown>
                <Bold>{dist.days}</Bold>
                {` days `}
                <Bold>{dist.hours}</Bold>
                {` hours `}
                <Bold>{dist.minutes}</Bold>
                {` min `}
                <Bold>{dist.seconds}</Bold>
                {` sec`}
              </CountDown>
            )}
          </Wrapper>
        )}
        {nextNight && (
          <Wrapper>
            <White>Next thurs.night</White>
            <Next>{nextNight && format(nextNight, 'MMMM dd, yyyy')}</Next>
            <NextTime>
              {nextNight && nextNightEnd && `${format(nextNight, 'h')}-${format(nextNightEnd, 'ha').toLowerCase()} PT`}
            </NextTime>
          </Wrapper>
        )}
   <Footer>
          <White>Want to show your work on thurs.night?</White>
          <White>
            <a href="https://linktr.ee/calarts.thurs.night/" target="_blank" rel="noopener noreferrer">
              submit your work here
            </a>
          </White>
          <White>Follow our socials:</White>
          <White>
            <a href="https://www.instagram.com/calarts.thurs.night/" target="_blank" rel="noopener noreferrer">
              Instagram | 
            </a>
            <a href="https://www.facebook.com/calarts.thurs.night/" target="_blank" rel="noopener noreferrer">
               Facebook
            </a>
          </White>
        </Footer>
        <Wrapper>
          <SlideshowTitle>Previously</SlideshowTitle>
          {archivedNights.map((night) => (
            <ArchiveLink>
              <Link key={night.name} to={`/night/${night.volume}`}>
                {`Vol ${night.volume} - ${format(new Date(night.start), 'MMMM dd, yyyy')}`}
              </Link>
            </ArchiveLink>
          ))}
        </Wrapper>
      </Content>
 
    </Page>
  )
}
const ArchiveLink = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 68px;
  line-height: 82px;
  text-align: center;
  margin-bottom: 32px;
  a {
    text-decoration: none;
    transition: all 0.2s ease-in-out;
  }
  &:hover a {
    color: #fade01;
  }
  @media ${mobile} {
    font-size: 24px;
    line-height: 100%;
    margin-bottom: 16px;
  }
`
const BackgroundContainer = styled.div`
  position: fixed;
  width: 50vw;
  bottom: -25vh;
  transform: translate(50%, 50%);
  left: 0;
  z-index: 0;
  svg path {
    fill-opacity: 0.03;
  }
  @media ${mobile} {
    width: 100vw;
    transform: translate(0, 50%);
  }
`
const Wrapper = styled.div`
  margin: 64px;
  @media ${se} {
    margin: 16px;
  }
`
const Bold = styled.span`
  font-family: 'Bw bold';
  font-weight: 800;
`
const Text = styled(m.p)`
  margin: 0;
  font-family: 'Bw';
  font-size: 24px;
  line-height: 43px;
  text-align: center;
  color: #676e74;
  @media ${mobile} {
    font-size: 18px;
    line-height: 32px;
  }
`
const White = styled(Text)`
  color: #fff;
`
const Next = styled.h3`
  font-size: 68px;
  color: #ff;
  margin: 0;
  font-family: 'Bw bold';
  @media ${mobile} {
    font-size: 32px;
  }
`
const CountDown = styled(Text)`
  font-size: 48px;
`
const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  widht: 100%;
`
const LogoContainer = styled.div`
  width: 80%;
  max-width: 1200px;
  svg path {
    fill: #fff;
  }
`
const Footer = styled.div`
  // position: absolute;
  // bottom: 128px;
  // width: 100%;
`
const Content = styled.div`
  position: relative;
  min-height: 100vh;
  z-index: 2;
  padding-top: 64px;
`
const SlideshowContainer = styled.div`
  width: 50vw;
  margin: 0 auto;
`
const Page = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background: #afb7c4;
  color: white;
  text-align: center;
  --bg: #fade01;
  --slideshow-height: 60vh;
  --slideshow-background: rgba(0, 0, 0, 0);
  a {
    color: white;
  }
`
const SlideshowTitle = styled.h1`
  color: var(--bg);
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 32px;
`
const NextTime = styled.h3`
  font-size: 30px;
  line-height: 36px;
` 
export { InactiveLanding }
