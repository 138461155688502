import styled from 'styled-components'
import { motion as m } from 'framer-motion'
import { mobile } from '../styles/Media'
import { Link } from 'react-router-dom'

export const Thumbnail = styled(m.img)`
  height: 100%;
  width: auto;
  max-width: 50%;
  object-fit: cover;
  filter: grayscale(100%);
  transition: all 0.3s ease-out;

  @media ${mobile} {
    display: none;
    filter: none;
  }
`

export const EventStatusText = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 16px;

  @media ${mobile} {
    flex: 1;
    text-align: center;
    font-size: 12px;
    p {
      max-width: 100%;
    }
  }
`

export const TextWrapper = styled.div`
  flex: 0;
  @media ${mobile} {
    flex: 1;
  }
`

export const EventStatus = styled(m.div)`
  background: var(--bg);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 5px;

  @media ${mobile} {
    flex-basis: 160px;
    flex-grow: 0;
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Type = styled.p`
  position: absolute;
  top: 0;
  left: 24px;
  color: #9facbb;

  @media ${mobile} {
    top: unset;
    bottom: 0;
    left: 8px;
  }
`

export const TitleContainer = styled(m.div)`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  flex-basis: 30%;

  @media ${mobile} {
    order: -1;
    padding: 8px;
  }
`

export const Title = styled(m.h1)`
  font-family: 'Bw bold';
  line-height: 1;
  // height: 60%;

  color: var(--bg);
  margin: 0;
  max-width: 90%;

  @media ${mobile} {
    margin: 18px 0 44px;
    font-size: 32px !important;
  }
`

export const EventRowContainer = styled(Link)`
  --bg: ${({ color }) => color};
  position: relative;
  height: 304px;
  border: 1px solid var(--bg);
  border-left: 0;
  border-right: 0;
  text-decoration: none;

  display: flex;

  margin-bottom: 64px;
  overflow: hidden;

  @media ${mobile} {
    height: auto;
    flex-direction: column;
    overflow: visible;
    margin-top: 100px;
    margin-bottom: 16px;
    border-bottom: 0;
  }

  &:hover {
    ${Thumbnail} {
      filter: grayscale(0%);
    }
  }
`

export const StageNameContainer = styled(m.div)`
  width: 64px;
  background: var(--bg);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 2;

  @media ${mobile} {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: unset;
    padding: 0 16px;
    transform: translateY(-100%);
  }
`

export const StageName = styled(m.h3)`
  font-family: 'Bw bold';
  font-weight: 800;
  white-space: nowrap;
  transform: rotate(270deg);
  text-transform: uppercase;

  @media ${mobile} {
    transform: none;
    margin: 8px 0;
  }
`

export const MobileThumbnail = styled(Thumbnail)`
  display: none;
  @media ${mobile} {
    display: block;
    height: 160px;
    margin-left: 8px;
    max-width: 60%;
    object-fit: cover;
  }
`
