import React from 'react'
import { useContentfulImage } from '../hooks'
import { Slideshow } from './Slideshow'
import styled from 'styled-components'
import { BlockContainer } from './Block.style'

const ImagesBlock = ({ data }) => {
  if (!data) {
    return null
  }

  if (data.isScrolling) {
    return (
      <Container>
        {data.images.map((image) => (
          <Image key={image.sys.id} image={image} />
        ))}
      </Container>
    )
  }

  return (
    <BlockContainer>
      <Slideshow images={data.images} />
    </BlockContainer>
  )
}

const Container = styled.div`
  margin: 32px 0;
`

const Image = ({ image }) => {
  const [src, desc, _, data] = useContentfulImage(image)

  const { width, height } = data.details.image
  return (
    <BlockContainer>
      <Img src={src} aspect={width / height} />
      <ImageDescription>{desc}</ImageDescription>
    </BlockContainer>
  )
}

const Img = styled.img`
  ${({ aspect }) =>
    aspect > 1
      ? `
  width: 100%;
  height: auto;
  `
      : `
  height: 80vh;
  width: auto;

    `}
`

export const ImageDescription = styled.p`
  font-size: 16px;
  line-height: 140%;
  color: #ffffff;
  max-width: 600px;
`

export const ImageDiv = styled.div`
  height: 80vh;
  width: 100%;
  background-image: url(${({ src }) => src});
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
`

export { ImagesBlock }
