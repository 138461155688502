import React from 'react'
import styled from 'styled-components'
import { BlockContainer } from './Block.style'

const EmbeddedFrameBlock = ({ data }) => {
  return (
    <MediaContainer>
      <Media
        src={data.url}
        frameBorder="0"
        allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </MediaContainer>
  )
}

const MediaContainer = styled(BlockContainer)`
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
const Media = styled.iframe``

export { EmbeddedFrameBlock }
