import React from 'react'
import styled from 'styled-components'
import { ContentfulText } from './ContentfulText'
import { BlockContainer } from './Block.style'

const DescriptionBlock = ({ data }) => {
  return (
    <TextContainer>
      <ContentfulText text={data.content} />
    </TextContainer>
  )
}

export const TextContainer = styled(BlockContainer)`
  font-size: 20px;
  max-width: 800px;
  color: #adb7c1;
  line-height: 140%;

  a {
    color: #adb7c1;
  }

  b,
  strong {
    color: rgb(201, 210, 219);
  }
`

export { DescriptionBlock }
