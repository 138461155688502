import styled from 'styled-components'
import { mobile } from '../styles/Media'
import { motion as m } from 'framer-motion'
import { Link } from 'react-router-dom'

export const MobileClaps = styled.div`
  grid-row: ui-start / ui-end;
  grid-column: ui-start / ui-end;
  margin-bottom: 40px;
`

export const BrowserView = styled.div`
  @media ${mobile} {
    display: none;
  }
`

export const MobileView = styled.div`
  display: none;
  @media ${mobile} {
    display: block;
  }
`

export const NavButtonContainer = styled.div`
  position: relative;
  grid-row: title-bar-start / title-bar-end;
  grid-column: ui-start / ui-end;
  display: flex;

  @media ${mobile} {
    // display: none;
    grid-row: nav-start / nav-end;
    grid-column: ui-start / ui-end;
  }
`

export const NavButton = styled.div`
  position: relative;
  background: var(--bg);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  flex: 1;
  z-index: 100;
  cursor: pointer;

  @media ${mobile} {
    justify-content: flex-start;
  }
`

export const NavText = styled.h3`
  color: #000;
  font-size: 16px;
  font-family: 'Bw bold';
  text-transform: uppercase;
  font-style: normal;
  font-weight: 800;

  @media ${mobile} {
    margin-left: 16px;
  }
`

export const NavigationContainer = styled.div`
  position: absolute;
  top: 100%;
  height: 500px;
  width: 100%;
  left: 0;
`

export const CommentSticky = styled.div`
  position: sticky;
  top: 16px;

  @media ${mobile} {
    position: relative;
    top: initial;
  }
`

export const CommentsContainer = styled.div`
  grid-row: page-start / page-end;
  grid-column: ui-start / ui-end;

  @media ${mobile} {
    grid-row: unset;
    grid-column: unset;
  }
`

export const TopNav = styled(Link)`
  position: relative;
  grid-row: top-nav-start / top-nav-end;
  grid-column: start / end;
  @media ${mobile} {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 8px;
  }
`

export const LogoContainer = styled.div`
  height: 100%;
  width: 590px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;

  @media ${mobile} {
    position: relative;
    height: unset;
    width: 100%;
    left: 0;
    transform: none;
  }
`

export const EventContent = styled.div`
  grid-column: event-start / event-end;
  grid-row: page-start / page-end;
  padding: 24px;
  padding-bottom: 100px;

  @media ${mobile} {
    padding: 16px;
    padding-bottom: 100px;
  }
`

export const TitleContainer = styled.div`
  grid-column: event-start / event-end;
  grid-row: title-bar-start / title-bar-end;
  border-top: 1px solid var(--bg);
  border-bottom: 1px solid var(--bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--bg);

  @media ${mobile} {
    border: 0;
  }
`

export const Title = styled(m.h1)`
  width: 80%;
  margin-left: 8px;

  font-family: 'Bw bold';

  @media ${mobile} {
    font-size: 48px !important;
    margin-bottom: 0;
  }
`

export const StageContainer = styled(m.div)`
  position: relative;
  display: grid;
  grid-template-rows:
    [start top-nav-start]
    98px [top-nav-end title-bar-start]
    160px [title-bar-end page-start]
    auto [page-end end];
  grid-template-columns:
    [start ui-start]
    min(40vw, 500px) [ui-end event-start]
    auto [event-end end];

  @media ${mobile} {
    overflow-x: hidden;
  }

  min-height: 100vh;

  @media ${mobile} {
    grid-template-rows:
      [start top-nav-start]
      160px [top-nav-end ]
      8px [spacer nav-start]
      60px [nav-end title-bar-start]
      auto [title-bar-end ui-start]
      auto [page-start ui-end]
      auto [page-end drawer-start]
      auto [drawer-end end];

    grid-template-columns:
      [start ui-start event-start]
      100vw [event-end ui-end end];
  }

  --bg: ${({ color }) => color};
`

export const DateTime = styled.div`
  position: absolute;
  right: 16px;
  bottom: 16px;

  @media ${mobile} {
    position: initial;
  }
`

const Thin = styled(m.p)`
  font-family: 'Bw thin';
  color: #adb7c1;
  font-weight: 300;
  font-size: 24px;
  margin: 0;

  @media ${mobile} {
    font-size: 14px;
  }
`

export const Date = styled(Thin)`
  @media ${mobile} {
    position: absolute;
    top: 8px;
    right: 0px;
  }
`
export const Clock = styled(Thin)`
  @media ${mobile} {
    position: absolute;
    bottom: 32px;
    right: 0px;
  }
`

export const Calarts = styled.img`
  position: absolute;
  left: 16px;
  top: 50%;
  height: 16px;
  width: auto;

  opacity: 0.4;

  @media ${mobile} {
    top: 8px;
    left: 0px;
  }
`

export const MobileUi = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 61px;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: space-around;
  align-items: center;
`

export const UiButton = styled.button`
  background: rgba(0, 0, 0, 0);
  color: #fff;
  font-size: 16px;
  line-height: 19px;
  border: 0;
  padding: 8px 32px;
  z-index: 100;
`

export const MobileUiContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 300px;
  background: #000;
`

export const CloseMobileUI = styled.button`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  background: black;
  font-size: 16px;
  padding: 8px 16px;
  border: 0;
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-family: inherit;
`
