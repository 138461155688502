import React, { useState, useEffect, useCallback, useContext, useReducer, useRef } from 'react'
import styled from 'styled-components'
import { db } from '../services/Firebase'
import { ClapIcon } from './ClapIcon'
import { TwitterIcon } from './TwitterIcon'
import { FacebookIcon } from './FacebookIcon'
import { ContentfulText } from './ContentfulText'
import { mobile } from '../styles/Media'
import { UserContext } from '../context/UserContext'
import TextareaAutosize from 'react-autosize-textarea'
import { format } from 'date-fns'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import { usePermalink, useEventStatus } from '../hooks'
import { motion as m } from 'framer-motion'
import { v4 as generateUuid } from 'uuid'
import axios from 'axios'
import { trackEvent } from '../services/Analytics'
import { useParams } from 'react-router-dom'

const id = generateUuid()

const Claps = ({ event, style }) => {
  const [claps, setClaps] = useState(0)

  const { nightId } = useParams()

  const [{ anims }, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'ADD_ANIMATION':
          return {
            count: state.count + 1,
            anims: [...state.anims, { key: state.count + 1, clapper: action.clapper }].slice(-3)
          }
        case 'REMOVE_ANIMATION':
          return { count: state.count, anims: state.anims.filter(({ key }) => key !== action.key) }
        default:
          return state
      }
    },
    { count: 0, anims: [] }
  )

  const onClapClick = useCallback(async () => {
    if (!nightId) {
      // try {
      //   trackEvent({ category: event.title, action: 'clap' })
      //   await axios.post('https://us-central1-calarts-thursdays.cloudfunctions.net/sendClap', { id: event.id })
      //   dispatch({ type: 'ADD_ANIMATION', clapper: true })
      // } catch (error) {
      //   console.error(error)
      // }
      db.ref(`/${event.id}/claps`).set({ count: claps + 1 })
      dispatch({ type: 'ADD_ANIMATION', clapper: true })
      trackEvent({ category: event.title, action: 'clap' })
    }
  }, [event, dispatch, nightId, claps])

  const eventStatus = useEventStatus(event)

  useEffect(() => {
    if (event) {
      db.ref(`/${event.id}/claps`).on('value', (snapshot) => {
        setClaps(snapshot.val()?.count || 0)
      })
    }
  }, [event, dispatch])

  return (
    <TopContainer style={style}>
      <EventType>
        <EventStatusText>{`>>><<<`}</EventStatusText>
        <EventStatusText>{event.eventSubType}</EventStatusText>
        <EventStatusText>{nightId ? 'Archived Event' : eventStatus}</EventStatusText>
        <EventStatusText>{`>>><<<`}</EventStatusText>
      </EventType>
      <ClapContainer>
        <ClapButton onClick={onClapClick} disabled={nightId}>
          {anims.map(({ key, clapper }) => (
            <ClapAnimationComponent
              key={key}
              remove={() => dispatch({ type: 'REMOVE_ANIMATION', key })}
              clapper={clapper}
            />
          ))}
          <ClapIcon />
        </ClapButton>
        <ClapCounter style={nightId ? { color: '#fff' } : {}}>{claps}</ClapCounter>
      </ClapContainer>
    </TopContainer>
  )
}

const ClapAnimationComponent = ({ remove, clapper }) => {
  useEffect(() => {
    const timeout = setTimeout(remove, 5000)
    return () => clearTimeout(timeout)
  }, [remove])

  if (clapper) {
    return (
      <ClapAnimationDiv
        initial={{ opacity: 0.3, scale: 0 }}
        animate={{ opacity: 0, scale: 5 }}
        transition={{ type: 'spring', mass: 5, damping: 100 }}
      />
    )
  }

  return (
    <ClapAnimationDiv
      style={{ background: 'rgba(0,0,0,0)' }}
      initial={{ opacity: 1, scale: 0 }}
      animate={{ opacity: 0, scale: 2 }}
      transition={{ type: 'spring', mass: 5, damping: 100 }}
    />
  )
}

const ClapAnimationDiv = styled(m.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: var(--bg);
  border: 1px solid var(--bg);
  pointer-events: none;
  user-select: none;
`

const Social = ({ event, style }) => {
  const permalink = usePermalink(event)
  return (
    <ShareWrapper style={style}>
      <ShareText>Share</ShareText>
      <TwitterShareButton title={event && event.title + '@ thurs.night'} url={permalink}>
        <TwitterButton>
          <TwitterIcon />
        </TwitterButton>
      </TwitterShareButton>
      <FacebookShareButton url={permalink}>
        <FacebookButton>
          <FacebookIcon />
        </FacebookButton>
      </FacebookShareButton>
    </ShareWrapper>
  )
}

const Comments = ({ event, style }) => {
  const [comment, setComment] = useState('')
  const [comments, setComments] = useState([])
  const { username, showUsernameForm } = useContext(UserContext)
  const [commentsDisabled, setCommentDisabled] = useState(false)
  const [commentLoading, setCommentLoading] = useState(false)
  const { nightId } = useParams()

  const sendComment = useCallback(
    async ({ content, id, username }) => {
      // if (!commentLoading) {
      trackEvent({ category: event.title, action: 'comment' })
      // try {
      //   setCommentLoading(true)
      //   await axios.post('https://us-central1-calarts-thursdays.cloudfunctions.net/sendComment', {
      //     content,
      //     id,
      //     username,
      //     timestamp: Date.now()
      //   })
      //
      //   setComment('')
      //   setCommentLoading(false)
      // } catch (error) {
      //   console.error(error)
      //   setCommentLoading(false)
      // }
      // }
      // Leaving this in in case something goes wrong with the cloud functions on thursday
      // Set write to true in firebase and uncomment this to use old commenting code
      db.ref(`/${id}/comments`)
        .push({
          content,
          user: username,
          timestamp: Date.now()
        })
        .then(() => {
          setComment('')
        })
    },
    [setComment, event.title]
  )

  const onCommentChange = useCallback((evt) => {
    setComment(evt.target.value)
  }, [])

  useEffect(() => {
    if (event) {
      const ref = db.ref(`/disabled_comments_list/${event.id}`)
      const cb = (ss) => setCommentDisabled(ss.val())

      ref.on('value', cb)

      return () => ref.off('value', cb)
    }
  }, [])

  useEffect(() => {
    if (event) {
      const ref = db.ref(`/${event.id}/comments`).orderByChild('timestamp').limitToLast(100)
      const cb = (snapshot) => {
        const tempComments = []
        snapshot.forEach((child) => {
          tempComments.push(child.val())
        })
        setComments(tempComments.reverse())
      }
      ref.on('value', cb)
      return () => ref.off('value', cb)
    }
  }, [event])

  const onSubmit = useCallback(
    (evt) => {
      evt.preventDefault()
      if (comment.length) {
        if (!username.length) {
          showUsernameForm((username) => {
            sendComment({
              username,
              content: comment,
              id: event.id
            })
          })
        } else {
          sendComment({
            username,
            content: comment,
            id: event.id
          })
        }
      }
    },
    [comment, event, username, showUsernameForm, sendComment]
  )

  return (
    <CommentsContainer style={style}>
      {!nightId && (
        <a style={{ marginBottom: '8px', color: '#8c979b' }} href="mailto:thurs.night@calarts.edu">
          <small>Report a comment</small>
        </a>
      )}
      {nightId ? (
        <p style={{ color: '#fff' }}>Comments closed.</p>
      ) : (
        <CommentForm onSubmit={onSubmit}>
          <CommentInput
            onChange={onCommentChange}
            value={comment}
            placeholder="Say something ( be respectful )"
            placeholderTextColor="#93979b"
            id="comment"
            onKeyDown={(evt) => {
              if (evt.keyCode === 13) {
                onSubmit(evt)
              }
            }}
          ></CommentInput>

          <PostButton animate={{ opacity: commentLoading ? 0.5 : 1 }} type="submit">
            ↓
          </PostButton>
        </CommentForm>
      )}

      <CommentsList>
        {commentsDisabled ? (
          <p>Comments have been disabled by a moderator </p>
        ) : (
          comments.map((c) => (
            <CommentContainer key={c.timestamp}>
              <CommentTimeStamp>{format(c.timestamp, 'h:mm bbbb')}</CommentTimeStamp>
              <CommentContentContainer>
                <CommentAuthor>{c?.user}</CommentAuthor>
                <CommentContent>{c.content}</CommentContent>
              </CommentContentContainer>
            </CommentContainer>
          ))
        )}
        {!comments.length && <p>It's quiet around here. Be the first to comment!</p>}
      </CommentsList>
    </CommentsContainer>
  )
}

const CommentAuthor = styled.p`
  font-size: 18px;
  line-height: 19px;
  font-family: 'Bw bold';
  margin: 0;
  margin-bottom: 4px;

  @media ${mobile} {
    font-size: 13px;
  }
`

const CommentContainer = styled.div`
  display: flex;
  color: #fff;
  align-items: start;
  margin-bottom: 16px;
  padding-right: 8px;
  flex-shrink: 0;
`

const CommentContentContainer = styled.div`
  flex: 1;
`

const CommentTimeStamp = styled.p`
  font-size: 13px;
  margin: 0;
  margin-right: 12px;
`

const CommentContent = styled.p`
  white-space: pre-line;
  margin: 0;
  font-size: 18px;
  word-break: break-word;

  @media ${mobile} {
    font-size: 13px;
  }
`

const TopContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  margin: 24px auto 0;
  max-width: 400px;
`

const EventType = styled.div`
  text-align: center;
  color: white;
  font-weight: lighter;
  font-size: 16px;
`

const CommentInput = styled(TextareaAutosize)`
  flex-grow: 1;
  border: 1px solid white;
  background-color: Transparent;
  color: red;
  padding: 8px 0 8px 20px;
  font-family: 'Bw';
  font-size: 16px;
  color: white;
`

const PostButton = styled(m.button)`
  margin-left: 4px;
  display: flex;
  border: 1px solid white;
  background-color: Transparent;
  color: white;
  text-align: center;
  justify-content: center;
  width: 41px;
  height: 41px;
  font-size: 24px;
`

const CommentsList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  margin: 20px;
  color: #93979b;
  overflow: auto;
  width: 100%;
  max-height: 600px;

  @media ${mobile} {
    margin: 8px;
    padding-right: 16px;
    max-height: 180px;
  }
`

const CommentForm = styled.form`
  display: flex;
  flex-direction: row;
  flex-basis: 80%;
  textalign: center;
`

const ShareWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-top: 48px;

  @media ${mobile} {
    align-items: center;
    height: 80%;
    margin-top: 0;
  }
`

const ShareText = styled.p`
  padding-right: 8px;
  vertical-align: middle;
  text-align: center;
  color: #93979b;

  @media ${mobile} {
    display: none;
  }
`

const SocialButton = styled.button`
  border: none;
  background-color: Transparent;

  @media ${mobile} {
    margin: 0 32px;
    svg {
      width: 80px;
      height: 80px;
    }
  }
`

const TwitterButton = styled(SocialButton)``

const FacebookButton = styled(SocialButton)``

const ClapContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${mobile} {
    flex-direction: row;
  }
`

const ClapButton = styled.button`
  position: relative;
  background-color: Transparent;
  border: 0;
  user-select: none;

  touch-action: manipulation;
  svg path {
    stroke: var(--bg);
  }

  @media ${mobile} {
    width: 80px;
    height: 80px;
    margin-right: 24px;

    svg {
      width: 80px;
      height: 80px;
    }
  }

  &:disabled {
    cursor: auto;
    svg path {
      stroke: #fff;
    }
  }
`

const CommentsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 20px;
  margin-top: 56px;

  @media ${mobile} {
    margin: 8px;
  }
`

const ClapCounter = styled.div`
  font-weight: bold;
  font-size: 20px;
  color: var(--bg);
  align-self: flex-end;

  @media ${mobile} {
    align-self: center;
    position: absolute;
    right: 0;
    transform: translateX(100%);
  }
`

export const EventStatusText = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin: 16px;
  @media ${mobile} {
    flex: 1;
    text-align: center;
    font-size: 12px;
    p {
      max-width: 100%;
    }
  }
`

export { Claps, Social, Comments }
