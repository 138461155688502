import React from 'react'
import styled from 'styled-components'
import { ContentfulText } from './ContentfulText'
import { BlockContainer } from './Block.style'
import { mobile } from '../styles/Media'

const TextContentBlock = ({ data }) => {
  return (
    <ContentTextWrapper>
      <ContentText>
        <ContentfulText text={data.content} />
      </ContentText>
    </ContentTextWrapper>
  )
}

export const ContentText = styled.div`
  color: #ffffff;
  max-width: 800px;
  font-weight: normal;
  font-size: 24px;
  line-height: 140%;
  padding: 36px;

  @media ${mobile} {
    padding: 16px;
    font-size: 20px;
  }
`

export const ContentTextWrapper = styled(BlockContainer)`
  background: #676e74;
`

export { TextContentBlock }
