import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch, useLocation, Redirect } from 'react-router-dom'
import { Landing } from './pages/Landing'
import { MainGallery } from './pages/MainGallery'
import { Night } from './pages/Night'
import { EventsProvider } from './context/EventsContext'
import { UserProvider } from './context/UserContext'
import { RoomRouter } from './RoomRouter'
import { useTracking } from './hooks'
import { trackPageView } from './services/Analytics'

function App() {
  useTracking()

  useEffect(() => {
    trackPageView(window.location)
  }, [])

  const { pathname, search } = useLocation()

  return (
    <>
      <RoomRouter />
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1) + search} />
        <Route exact path="/" component={Landing} />
        <Route path="/night/:nightId" component={Night} />
        <Route path="/maingallery" component={MainGallery} />
      </Switch>
    </>
  )
}

export default () => (
  <div className="App">
    <EventsProvider>
      <UserProvider>
        <Router>
          <App />
        </Router>
      </UserProvider>
    </EventsProvider>
  </div>
)
