import ReactGA from 'react-ga'

export const shouldTrack = () => process.env.NODE_ENV === 'production'

export const initialize = (trackingId) => {
  if (shouldTrack()) {
    ReactGA.initialize(trackingId)
  }
}

export const trackPageView = (location) => {
  if (shouldTrack()) {
    ReactGA.set({ page: location.pathname + location.search })
    ReactGA.pageview(location.pathname + location.search)
  }
}

export const trackEvent = (event) => {
  if (shouldTrack()) {
    ReactGA.event(event)
  }
}
