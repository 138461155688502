import React from 'react'
import { useTitleSize, useContentfulImage, useIsMobile, useEventStatus, useStage } from '../hooks'
import slugify from 'slugify'
import {
  EventRowContainer,
  StageNameContainer,
  StageName,
  Thumbnail,
  TitleContainer,
  Title,
  Type,
  EventStatus,
  MobileThumbnail,
  EventStatusText,
  TextWrapper
} from './EventRow.style'
import { useRouteMatch, useParams } from 'react-router-dom'

const EventRow = ({ event, color }) => {
  const { title, eventType, thumbnail, eventSubType, stage: stageId } = event

  const stage = useStage(stageId)
  const [src] = useContentfulImage(thumbnail)
  const isMobile = useIsMobile()
  const eventStatus = useEventStatus(event)
  const { url } = useRouteMatch()

  const { fontSize } = useTitleSize(title)
  const { nightId } = useParams()

  return (
    <EventRowContainer
      to={nightId ? `${url}/${slugify(stage.toLowerCase())}` : `/${slugify(stage.toLowerCase())}`}
      color={color || '#808080'}
    >
      <StageNameContainer>
        <StageName>{stage}</StageName>
      </StageNameContainer>
      <Thumbnail src={src} />

      <TitleContainer>
        <Type>{eventType}</Type>
        <Title style={{ x: isMobile ? 0 : -16, fontSize: isMobile ? '' : fontSize }}>{title}</Title>
      </TitleContainer>
      <EventStatus>
        <MobileThumbnail src={src} />
        <TextWrapper>
          <EventStatusText>{`>>><<<`}</EventStatusText>
          <EventStatusText>{eventSubType}</EventStatusText>
          <EventStatusText>{eventStatus}</EventStatusText>
          <EventStatusText>{`>>><<<`}</EventStatusText>
        </TextWrapper>
      </EventStatus>
    </EventRowContainer>
  )
}

export { EventRow }
