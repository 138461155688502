import firebase from 'firebase'

const config = {
  apiKey: 'AIzaSyDCYIvB27HsX9-CiRdg-vUa0MkrBH4SIac',
  authDomain: 'thurs-night.firebaseapp.com',
  databaseURL: 'https://thurs-night.firebaseio.com',
  projectId: 'thurs-night',
  storageBucket: 'thurs-night.appspot.com',
  messagingSenderId: '660930626382',
  appId: '1:660930626382:web:5b0e249fb54f8930c6941f',
  measurementId: 'G-NM7GZZM7DN'
}

firebase.initializeApp(config)

export const db = firebase.database()
