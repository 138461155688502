import React from 'react'
import styled from 'styled-components'
import { BlockContainer } from './Block.style'
import { mobile } from '../styles/Media'

const SubHeadingBlock = ({ data }) => {
  return (
    <SubHeadingContainer>
      <SubHeading>{data.content}</SubHeading>
    </SubHeadingContainer>
  )
}

const SubHeadingContainer = styled(BlockContainer)`
  margin-bottom: 16px;
`

const SubHeading = styled.h3`
  font-size: 36px;
  font-family: 'Bw bold';
  font-weight: normal;
  margin: 0;
  color: var(--bg);
  word-wrap: break-word;

  @media ${mobile} {
    font-size: 26px;
  }
`

export { SubHeadingBlock }
