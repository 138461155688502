import React from 'react'
import { motion as m } from 'framer-motion'

const Chevron = (props) => (
  <m.svg width="22" height="11" viewBox="0 0 22 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M21 10.2649L11 1.26489L1 10.2649" stroke="black" />
  </m.svg>
)

const ChevronLeft = (props) => {
  return (
    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M7.3 1L1 8L7.3 15" stroke="#676E74" stroke-width="0.7" />
    </svg>
  )
}

export { Chevron, ChevronLeft }
