import React, { useState, useEffect } from 'react'
import { getBlock } from '../services/Contentful'
import { ImagesBlock } from './ImagesBlock'
import { DescriptionBlock } from './DescriptionBlock'
import { TextContentBlock } from './TextContentBlock'
import { ExternalLinkBlock } from './ExternalLinkBlock'
import { EmbeddedFrameBlock } from './EmbeddedFrameBlock'
import { SubHeadingBlock } from './SubHeadingBlock'

const Block = ({ blockData }) => {
  const [block, setBlock] = useState(null)
  useEffect(() => {
    getBlock(blockData.sys.id).then(({ data }) => {
      setBlock({
        type: data?.sys?.contentType?.sys?.id,
        ...data.fields
      })
    })
  }, [blockData])

  if (!block) {
    return null
  }

  if (block.type === 'imagesBlock') {
    return <ImagesBlock data={block} />
  }

  if (block.type === 'descriptionBlock') {
    return <DescriptionBlock data={block} />
  }

  if (block.type === 'textContentBlock') {
    return <TextContentBlock data={block} />
  }

  if (block.type === 'externalLinkBlock') {
    return <ExternalLinkBlock data={block} />
  }

  if (block.type === 'embeddedFrameBlock') {
    return <EmbeddedFrameBlock data={block} />
  }

  if (block.type === 'subHeadingBlock') {
    return <SubHeadingBlock data={block} />
  }

  return <h1>Block</h1>
}

export { Block }
