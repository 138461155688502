import axios from 'axios'
// Ugh, how do I fix this, I hate this
const contentful = require('contentful')

// TODO: Move asset reads to contentful client so I don't have these 2 config objects
const config = {
  space_id: process.env.REACT_APP_SPACE_ID,
  access_token: process.env.REACT_APP_ACCESS_TOKEN
}

const contentfulConfig = {
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_ACCESS_TOKEN
}

const client = contentful.createClient(contentfulConfig)

const baseUrl = 'https://cdn.contentful.com'

export const getStages = async () => {
  try {
    const response = await client.getEntries({
      content_type: 'stage'
    })

    const stages = response.items
      .map((item) => [item.sys.id, item.fields.name])
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

    return stages
  } catch (error) {
    console.error(error)
    return error
  }
}

export const getNights = async () => {
  try {
    const response = await client.getEntries({
      content_type: 'night'
    })

    const nights = response.items
      .filter((item) => item.sys.contentType.sys.id === 'night')
      .map((item) => [
        item.sys.id,
        {
          ...item.fields
        }
      ])
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

    return nights
  } catch (error) {
    console.error(error)
    return error
  }
}

export const getMisc = async () => {
  try {
    const response = await client.getEntries({
      content_type: 'miscContent'
    })

    return response.items
      .map((item) => [
        item.fields.title,
        {
          id: item.sys.id,
          ...item.fields
        }
      ])
      .reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {})
  } catch (error) {
    console.error(error)
    return error
  }
}

export const getHappeningsForNight = async (nightId) => {
  try {
    const response = await client.getEntries({
      content_type: 'happening',
      'fields.night.sys.id': nightId
    })

    const happenings = response.items.map((item) => ({
      id: item.sys.id,
      ...item.fields,
      night: nightId
    }))

    return happenings
  } catch (error) {
    console.error(error)
    return error
  }
}

export const getEventsForNight = async (nightId) => {
  try {
    const response = await client.getEntries({
      content_type: 'event',
      'fields.night.sys.id': nightId
    })

    const events = response.items.map((item) => ({
      id: item.sys.id,
      ...item.fields,
      night: nightId,
      stage: item.fields?.stage?.sys?.id
    }))

    return events
  } catch (error) {
    console.error(error)
    return error
  }
}

export const getLandingPageUpcoming = async () => {
  try {
    const response = await client.getEntry(process.env.REACT_APP_LANDING_PAGE_UPCOMING_ID)
    return response
  } catch (error) {
    console.error(error)
    return error
  }
}

export const getLandingPagePrevious = async () => {
  try {
    const response = await client.getEntry(process.env.REACT_APP_LANDING_PAGE_PREVIOUS_ID)
    return response
  } catch (error) {
    console.error(error)
    return error
  }
}

export const getAsset = (assetId) => {
  return axios.get(
    baseUrl + `/spaces/${config.space_id}/environments/master/assets/${assetId}?access_token=${config.access_token}`
  )
}

export const getBlock = (entryId) =>
  axios.get(
    baseUrl + `/spaces/${config.space_id}/environments/master/entries/${entryId}?access_token=${config.access_token}`
  )
