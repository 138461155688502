import React, { useContext, useEffect } from 'react'
import { MainGallery } from './MainGallery'
import { useParams, Link, useLocation, Route, Switch, useRouteMatch } from 'react-router-dom'
import slugify from 'slugify'
import { EventsContext } from '../context/EventsContext'
import { Stage } from './Stage'
import styled from 'styled-components'
import { ArchiveBar } from '../components/ArchiveBar'

const Night = () => {
  const { nightId } = useParams()
  const { nights, stages } = useContext(EventsContext)
  const { path } = useRouteMatch()
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <NightContainer>
      <ArchiveBar />
      <NightContent>
        <Switch>
          <Route exact path={`${path}`}>
            <MainGallery
              isArchive={true}
              nightId={Object.keys(nights).find((key) => nights[key].volume === parseInt(nightId))}
            />
          </Route>
          {Object.keys(stages).map((key) => (
            <Route
              key={key}
              path={`${path}/${slugify(stages[key].toLowerCase())}`}
              render={(routerProps) => <Stage stageId={key} stageName={stages[key]} {...routerProps} />}
            />
          ))}
        </Switch>
      </NightContent>
    </NightContainer>
  )
}

const NightContent = styled.div`
  position: absolute;
  width: 100%;
  top: 36px;
`
const NightContainer = styled.div`
  position: relative;
`

export { Night }
