import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { EventsContext } from '../context/EventsContext'
import { useParams, useHistory, useRouteMatch } from 'react-router-dom'
import { colors } from '../services/EventColors'
import { motion as m, useInvertedScale } from 'framer-motion'
import slugify from 'slugify'
import { useStage } from '../hooks'

const RowVariant = {
  enter: {
    y: -100,
    opacity: 0
  },
  in: {
    y: 0,
    opacity: 1
  }
}

const RowTransition = {
  type: 'spring',
  mass: 0.5,
  damping: 100
}

const ContainerVariants = {
  enter: {
    opacity: 0,
    pointerEvents: 'none',
    transition: {
      type: 'spring',
      mass: 0.1,
      damping: 100
    }
  },
  in: {
    opacity: 1,
    pointerEvents: 'all',
    transition: {
      staggerChildren: 0.05
    }
  }
}

const RoomNavigation = ({ event, setNavOpen }) => {
  const [events, setEvents] = useState([])
  const { currentEvents, stages, nights, getEvents } = useContext(EventsContext)
  const history = useHistory()
  const { nightId } = useParams()

  const { url } = useRouteMatch()
  const basePath = url.split('/').slice(0, -1).join('/')

  useEffect(() => {
    if (nightId) {
      const night = Object.keys(nights).find((key) => nights[key].volume === parseInt(nightId))
      getEvents(night).then(setEvents)
    } else {
      setEvents(currentEvents)
    }
  }, [])

  if (!events.length) {
    return null
  }

  return (
    <RoomNavigationWrapper variants={ContainerVariants} initial="enter" animate="in" exit="enter">
      {events.map((e) => (
        <StageWrapper
          variants={RowVariant}
          transition={RowTransition}
          style={{
            background: e.id === event.id ? `rgba(0, 0, 0, 0)` : colors[e.color],
            color: e.id === event.id ? colors[e.color] : '#000',
            border: e.id === event.id ? `1px solid ${colors[e.color]}` : 0
          }}
          onClick={() => {
            setNavOpen(false)

            const stageName = stages[e.stage]
            if (stageName) {
              history.push(`${basePath}/${slugify(stageName.toLowerCase())}`)
            }
          }}
        >
          <StageName stage={e.stage} />
        </StageWrapper>
      ))}
      {!nightId && (
        <StageWrapper
          positionTransition
          variants={RowVariant}
          transition={RowTransition}
          style={{ background: '#676E74' }}
          onClick={() => {
            setNavOpen(false)
            history.push('/#sublevel')
          }}
        >
          <StageNameEle>sub.level</StageNameEle>
        </StageWrapper>
      )}
    </RoomNavigationWrapper>
  )
}

const StageName = ({ stage }) => {
  const { scaleX, scaleY } = useInvertedScale()
  const stageName = useStage(stage)
  return <StageNameEle style={{ scaleX, scaleY }}>{stageName}</StageNameEle>
}

const StageWrapper = styled(m.div)`
  height: 65px;
  box-sizing: border-box;

  flex-grow: 1;
  flex-basis: 40%;
  max-width: calc(50% - 2px);
  margin: 2px 4px;
  margin-left: 0;

  &:nth-of-type(even) {
    margin-right: 0;
  }
  color: #000;

  display: flex;
  align-items: center;
  cursor: pointer;
`

const StageNameEle = styled(m.h3)`
  text-transform: uppercase;
  text-align: center;
  flex: 1;
  font-size: 16px;
  font-family: 'Bw bold';
  text-transform: uppercase;
  font-style: normal;
  font-weight: 800;
`

const RoomNavigationWrapper = styled(m.div)`
  display: flex;
  flex-wrap: wrap;
  // flex-direction: column;
  font-weight: 900;
  text-align: center;
  background: #263039;
  margin-top: 2px;
`

export { RoomNavigation }
