import React, { useContext, useMemo } from 'react'
import { EventsContext } from '../context/EventsContext'
import { format } from 'date-fns'
import styled from 'styled-components'
import { mobile } from '../styles/Media'

const UpNext = () => {
  const { nextEvents, nextNight, nights } = useContext(EventsContext)

  const nextNightObj = nights[nextNight]

  if (!nextEvents.length || !nextNightObj || !nextNightObj.start || !nextNightObj.end) {
    return null
  }

  return (
    <Container>
      <Title>
        next <br />
        thurs.night <br />
      </Title>
      <DateTimeRow>
        <DateEle>{format(new Date(nextNightObj.start), 'MMM d')}</DateEle>
        <Time>{`${format(new Date(nextNightObj.start), 'hh')}-${format(new Date(nextNightObj.end), 'hh')} PT`}</Time>
      </DateTimeRow>
      {nextEvents.map((event) => (
        <EventTitle key={event.id}>{event.title}</EventTitle>
      ))}
    </Container>
  )
}

const DateTimeRow = styled.div`
  color: #fff;
`
const DateEle = styled.h3`
  font-family: 'Bw bold';
  font-size: 56px;
  margin: 0;
  margin-right: 16px;
  display: inline;

  @media ${mobile} {
    font-size: 32px;
  }
`
const Time = styled.h3`
  font-size: 30px;
  margin: 0;
  display: inline;
  @media ${mobile} {
    font-size: 24px;
  }
`

const Title = styled.h1`
  font-family: 'Bw';
  font-style: normal;
  font-weight: 300;
  font-size: 130.267px;
  line-height: 90%;

  color: #676e74;
  margin: 0;
  margin-bottom: 64px;

  @media ${mobile} {
    font-size: 52px;
    margin-bottom: 16px;
  }
`

const Container = styled.div`
  position: relative;
  max-width: 600px;
  margin: 0 64px;

  @media ${mobile} {
    margin: 16px;
  }
`
const EventTitle = styled.h1`
  font-family: 'Bw bold';
  color: #fade01;
  font-size: 64px;

  @media ${mobile} {
    font-size: 24px;
  }
`

export { UpNext }
