import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { EventsContext } from '../context/EventsContext'
import styled from 'styled-components'
import { Logo } from './Logo'
import { motion as m } from 'framer-motion'
import { mobile } from '../styles/Media'
import { useTime } from '../hooks'
import { format } from 'date-fns'
import CalartsLogo from '../assets/calarts-logo.png'

const Header = () => {
  const { nightId } = useParams()
  const { nightObj, nights } = useContext(EventsContext)
  const archiveNight = nights[Object.keys(nights).find((key) => nights[key].volume === parseInt(nightId))]
  const date = useTime(1000 * 30)
  const time = format(date, 'h:mm a')
  const day = format(archiveNight && archiveNight.start ? new Date(archiveNight.start) : date, 'MMM d, yyyy')
  return (
    <HeaderContainer>
      {!nightId && <Clock>{time}</Clock>}
      <DateEle>{day}</DateEle>
      <Volume>Vol. {nightId ? nightId : nightObj.volume}</Volume>
      <Calarts src={CalartsLogo} />
      <LogoContainer>
        <Logo />
      </LogoContainer>
    </HeaderContainer>
  )
}

const Thin = styled(m.p)`
  font-family: 'Bw thin';
  color: #adb7c1;
  font-weight: 300;
  position: absolute;
  font-size: 36px;
  margin: 0;

  @media ${mobile} {
    font-size: 14px;
  }
`

const DateEle = styled(Thin)`
  right: 0;
  top: 24px;

  @media ${mobile} {
    top: 0;
  }
`

const Volume = styled(Thin)`
  bottom: 24px;
  right: 0;

  @media ${mobile} {
    bottom: 0;
    left: 0;
    right: unset;
  }
`

const Clock = styled(Thin)`
  top: 24px;
  left: 0;

  @media ${mobile} {
    left: unset;
    top: unset;
    right: 0;
    bottom: 0;
  }
`

const LogoContainer = styled(m.div)`
  width: 100%;
  height: 100%;
`

const Calarts = styled(m.img)`
  position: absolute;
  left: 12px;
  top: 51%;
  transform-origin: center;
  color: #fff;
  height: 1vh;
  max-height: 14px;
  width: auto;
  transform: translateX(-50%) rotate(270deg);

  @media ${mobile} {
    transform: none;
    top: 0;
    left: 0;
    height: 12px;
  }
`

const HeaderContainer = styled.div`
  position: relative;
  padding: 32px 75px;
  padding-bottom: 0;
  margin-bottom: -1%;

  @media ${mobile} {
    padding: 0;
    margin: 32px 8px 64px;
    padding-top: 16px;
  }
`

export { Header }
