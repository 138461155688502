import React from 'react'
import { useParams, useLocation, Link } from 'react-router-dom'
import { ChevronLeft } from './Chevron'
import styled from 'styled-components'
import { mobile } from '../styles/Media'

const ArchiveBar = () => {
  const { nightId } = useParams()
  const { pathname } = useLocation()
  const isRoot = pathname === `/night/${nightId}`

  return (
    <Bar>
      <Back to={isRoot ? '/' : `/night/${nightId}`}>
        <ChevronLeft />
        Back
      </Back>
      <p>Archived Event</p>
    </Bar>
  )
}

const Bar = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 36px;
  background: #fff;
  z-index: 111;
  display: flex;
  justify-content: space-around;
  align-items: center;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: #676e74;
    text-transform: uppercase;

    @media ${mobile} {
      font-size: 12px;
    }
  }
`

const Back = styled(Link)`
  position: absolute;
  left: 44px;
  flex: 1;
  display: flex;
  align-items: center;

  color: #676e74;
  height: 100%;
  text-decoration: none;

  svg {
    margin-right: 6px;
  }

  @media ${mobile} {
    left: 8px;
    font-size: 12px;
  }
`

export { ArchiveBar }
