import React from 'react'
import { motion as m } from 'framer-motion'

const Logo = () => {
  return (
    <m.svg viewBox="0 0 1002 218" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M77.4538 77.4523V57.8059H18.0914V57.4281C35.2405 54.2166 45.6054 44.3934 45.6054 30.6031V21.5355H17.1491V57.8059H0V77.4523H15.4531V133.369C15.4531 155.471 29.0216 168.317 52.0128 168.317C60.1162 168.317 68.9735 166.428 75.5693 163.406V142.248C71.6118 144.326 66.3352 145.648 61.0585 145.648C50.3167 145.648 43.9093 139.792 43.9093 129.591V77.4523H77.4538Z"
        fill="#676E74"
      />
      <path
        d="M146.804 55.7279C124.755 55.7279 111.752 70.6516 111.752 95.9653H111.187V0H82.7305V166.239H111.187V107.489C111.187 89.1646 120.044 78.208 135.12 78.208C148.5 78.208 156.227 86.8978 156.227 101.444V166.239H184.683V100.121C184.683 72.1629 170.738 55.7279 146.804 55.7279Z"
        fill="#676E74"
      />
      <path
        d="M268.921 57.8059V116.367C268.921 134.88 260.441 145.648 246.307 145.648C233.68 145.648 226.142 137.147 226.142 122.601V57.8059H197.686V123.924C197.686 151.693 211.443 168.317 234.811 168.317C256.106 168.317 268.544 153.205 268.544 127.891H268.921V166.239H297.377V57.8059H268.921Z"
        fill="#676E74"
      />
      <path
        d="M374.077 55.7279C353.536 55.7279 341.287 72.9185 341.287 102.577H340.91V57.8059H312.454V166.239H340.91V117.312C340.91 94.4541 350.898 80.8527 367.482 80.8527C372.947 80.8527 378.223 82.364 382.558 84.8198L390.661 59.3172C386.327 57.0503 380.296 55.7279 374.077 55.7279Z"
        fill="#676E74"
      />
      <path
        d="M434.571 168.317C465.289 168.317 483.757 154.905 483.757 132.614C483.757 107.111 460.577 103.333 441.355 99.9324C427.598 97.4766 415.726 95.5875 415.726 85.9532C415.726 78.0191 422.887 73.2964 434.759 73.2964C447.197 73.2964 454.358 79.7192 454.358 91.2426H480.93C480.93 68.7626 463.781 55.539 434.759 55.539C405.738 55.539 388.4 67.818 388.4 88.409C388.4 113.156 410.826 116.934 429.483 119.957C443.616 122.412 456.431 124.49 456.431 136.014C456.431 145.081 448.516 150.182 434.759 150.182C420.625 150.182 412.145 143.003 412.145 130.913H385.573C385.573 154.527 403.665 168.317 434.571 168.317Z"
        fill="#676E74"
      />
      <path
        d="M603.235 55.7279C581.186 55.7279 568.183 70.6516 568.183 95.9653H567.806V57.8059H539.35V166.239H567.806V107.489C567.806 89.1646 576.664 78.208 591.74 78.208C605.12 78.208 612.846 86.8977 612.846 101.444V166.239H641.303V100.121C641.114 72.1629 627.169 55.7279 603.235 55.7279Z"
        fill="#676E74"
      />
      <path d="M683.893 57.8059H655.437V166.239H683.893V57.8059Z" fill="#676E74" />
      <path
        d="M776.234 57.8059V95.0208H775.857C775.857 70.4627 761.346 55.7279 737.224 55.7279C710.087 55.7279 694.069 76.5078 694.069 111.456C694.069 146.404 710.087 167.184 737.224 167.184C761.346 167.184 775.857 152.449 775.857 127.891H776.234V169.262C776.234 185.697 766.623 195.331 750.416 195.331C735.905 195.331 726.106 187.775 724.41 175.118L697.273 179.085C701.23 203.454 721.018 218 750.228 218C784.337 218 804.502 199.298 804.502 167.562V57.8059H776.234ZM750.416 144.515C733.267 144.515 723.091 132.236 723.091 111.456C723.091 90.6759 733.267 78.208 750.416 78.208C767.565 78.208 777.742 91.8094 777.742 111.456C777.742 131.102 767.565 144.515 750.416 144.515Z"
        fill="#676E74"
      />
      <path
        d="M883.463 55.7279C861.414 55.7279 848.411 70.6516 848.411 95.9653H848.034V0H819.578V166.239H848.034V107.489C848.034 89.1646 856.892 78.208 871.968 78.208C885.348 78.208 893.074 86.8978 893.074 101.444V166.239H921.531V100.121C921.342 72.1629 907.397 55.7279 883.463 55.7279Z"
        fill="#676E74"
      />
      <path
        d="M1002 77.4523V57.8059H942.638V57.4281C959.787 54.2166 970.152 44.3934 970.152 30.6031V21.5355H941.696V57.8059H924.546V77.4523H939.999V133.369C939.999 155.471 953.568 168.317 976.559 168.317C984.663 168.317 993.52 166.428 1000.12 163.406V142.248C996.158 144.326 990.881 145.648 985.605 145.648C974.863 145.648 968.456 139.792 968.456 129.591V77.4523H1002Z"
        fill="#676E74"
      />
      <path
        d="M655.625 57.4281H656.567C673.716 54.2166 684.081 44.3934 684.081 30.6031V21.5355H655.625V57.4281Z"
        fill="#676E74"
      />
      <path
        d="M494.875 167.75H495.818C512.967 164.539 523.332 154.716 523.332 140.925V131.858H494.875V167.75Z"
        fill="#676E74"
      />
    </m.svg>
  )
}

export { Logo }
