import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { useContentfulImage } from '../hooks'
import { motion as m, AnimatePresence } from 'framer-motion'

const Chevron = ({ right, ...rest }) =>
  right ? (
    <svg width="16" height="33" viewBox="0 0 16 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M0.934812 31.4414L14.4348 16.4414L0.934811 1.44141" stroke="#FADE01" strokeWidth="2" />
    </svg>
  ) : (
    <svg width="17" height="33" viewBox="0 0 17 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M15.4812 1.44141L1.9812 16.4414L15.4812 31.4414" stroke="#FADE01" strokeWidth="2" />
    </svg>
  )

const Preload = ({ images }) => (
  <PreloadContainer>
    {images.map((image) => (
      <Image key={image.sys.id} image={image} />
    ))}
  </PreloadContainer>
)

const ImageVariants = {
  enter: (dir) => {
    return {
      x: dir > 0 ? '100%' : '-100%',
      opacity: 0
    }
  },
  in: {
    x: 0,
    opacity: 1
  },
  leave: (dir) => {
    return {
      x: dir < 0 ? '100%' : '-100%',
      opacity: 0
    }
  }
}

const Image = ({ image }) => {
  const [src, desc] = useContentfulImage(image)
  return (
    <>
      <ImageDiv src={src} />
      <ImageDesc>{desc}</ImageDesc>
    </>
  )
}

const Slideshow = ({ images }) => {
  const [[position, dir], setPosition] = useState([0, 0])

  const paginate = useCallback(
    (newDir) => {
      let newPos = (position + newDir) % images.length
      if (newPos < 0) {
        newPos = images.length - 1
      }
      setPosition([newPos, newDir])
    },
    [position, images]
  )

  if (!images) {
    return null
  }
  return (
    <SlideshowContainer>
      <ImageContainer>
        <Background />
        <AnimatePresence custom={dir} initial={false}>
          <Wrapper
            initial="enter"
            animate="in"
            exit="leave"
            custom={dir}
            key={position}
            variants={ImageVariants}
            transition={{ type: 'tween', ease: 'easeInOut', duration: 0.3 }}
          >
            <Image image={images[position]} />
          </Wrapper>
        </AnimatePresence>
      </ImageContainer>
      <Controls>
        <div style={{ cursor: 'pointer' }}>
          <Chevron onClick={() => paginate(-1)} />
        </div>
        <ControlsText>{`${position + 1} / ${images.length}`}</ControlsText>
        <div style={{ cursor: 'pointer' }}>
          <Chevron right onClick={() => paginate(1)} />
        </div>
      </Controls>
      <Preload images={images} />
    </SlideshowContainer>
  )
}

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--slideshow-height);
  background: var(--slideshow-background);
`

const PreloadContainer = styled.div`
  position: fixed;
  top: 1000vh;
  left: 1000vw;
  height: 1px;
  width: 1px;
  pointer-events: none;
  opacity: 0;
`

const ImageDesc = styled(m.p)`
  color: #fff;
  overflow: hidden;
  // height: 5%;
  max-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 140%;
`

const ImageDiv = styled.div`
  width: 100%;
  height: 94%;
  flex: 1;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`

const ImageContainer = styled.div`
  position: relative;
  min-height: var(--slideshow-height);
  display: flex;
`

const Wrapper = styled(m.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ControlsText = styled.p`
  margin: 0 16px;
  color: var(--bg);
  font-family: 'Bw bold';
  font-size: 14px;
`

const Controls = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--bg);
  padding-top: 24px;
`

const SlideshowContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  img {
    display: block;
    margin: 0 auto;
  }

  svg path {
    stroke: var(--bg);
  }
`

export { Slideshow }
